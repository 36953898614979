<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <b-card :title="'Lesson Issue Solve'">
          <b-card-text>
            <div class="form-group">
              <label for="name">Course Status</label>
              <Input
                name="name"
                type="number"
                v-model="status"
                id="name"
                placeholder="Enter Course Status"
              />
            </div>
            <b-button
              type="submit"
              class="float-right"
              variant="primary"
              @click="lessonSolve"
            >
              Update
            </b-button>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card :title="'Email Marge'">
          <b-card-text>
            <div class="form-group">
              <label for="email">Email</label>
              <Input
                name="email"
                type="email"
                v-model="email"
                id="email"
                placeholder="Enter Email"
              />
            </div>
            <div class="form-group">
              <label for="name">Number</label>
              <Input
                name="name"
                type="text"
                v-model="number"
                id="name"
                placeholder="Enter Number"
              />
            </div>
            <b-button
              type="submit"
              class="float-right"
              variant="primary"
              @click="mailMarge"
            >
              Update
            </b-button>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { Button, HasError, AlertError } from "vform/src/components/bootstrap5";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      status: null,
      email: null,
      number: null,
    };
  },
  mounted() {},
  methods: {
    async lessonSolve() {
      if (this.status == null || this.status == "") {
        return this.i("Status Field Is Required");
      }
      const lesson = await this.callApi(
        "post",
        "/app/student/problem/solve/" + this.status
      );
      if (lesson.status == 200) {
        this.status = null;
        this.s(lesson.data.messege);
      }
    },
    async mailMarge() {
      if (this.email == null || this.email == "") {
        return this.i("Email Field Is Required");
      }
      if (this.number == null || this.number == "") {
        return this.i("Number Field Is Required");
      }
      const email_marge = await this.callApi(
        "post",
        "/app/student/email/marge/" + this.email + "/" + this.number
      );
      if (email_marge.status == 200) {
        this.email = null;
        this.number = null;
        this.s(email_marge.data.messege);
      }
    },
  },
  watch: {},
  components: {
    Button,
    HasError,
    AlertError,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
};
</script>

<style></style>
